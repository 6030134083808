import React, { useContext, useState, useEffect } from "react";
import "./Header.css";
import { Link, useHistory } from "react-router-dom";
import dronerequest2 from "../../assets/logo_textonly.png";
import { AccountContext } from "../../contexts/AccountContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import { IS_TEST } from "../../constants/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IOSSwitch, Android12Switch } from "./Switch";

export const Header = ({ lng, setLng }) => {
  const { user } = useContext(AccountContext);

  return <>{user === null ? <HeaderLoggedOut lng={lng} setLng={setLng} /> : <HeaderLoggedIn lng={lng} setLng={setLng} />}</>;
};

const HeaderLoggedOut = ({ lng, setLng }) => {
  const { i18n, t } = useTranslation();

  const handleChange = (event) => {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
    document.cookie = `LANG=${event.target.value}; expires=${expires}`;
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "#000000", color: "#000000" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Link sx={{ display: "flex", flexGrow: 1 }} to="/">
          <img className="header-image" src={dronerequest2} alt="" style={{ height: 60 }} />
        </Link>
        {IS_TEST && (
          <div class="blink_me" style={{ color: "white", fontWeight: "bold", display: "flex", flexGrow: 1 }}>
            TEST
          </div>
        )}
        <Select
          sx={{ borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }}
          size="small"
          value={lng}
          inputProps={{
            name: "language",
            id: "uncontrolled-native",
          }}
          id="lang-select"
          onChange={handleChange}
        >
          <MenuItem value={"sv-SE"}>{t("swedish")}</MenuItem>
          <MenuItem value={"en-GB"}>{t("english")}</MenuItem>
        </Select>
      </Toolbar>
    </AppBar>
  );
};

const HeaderLoggedIn = ({ lng, setLng }) => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AccountContext);

  const [checked, setChecked] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let history = useHistory();

  return (
    <AppBar position="static" style={{ backgroundColor: "#000000", color: "#000000" }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          sx={{ display: "flex", flexGrow: 1 }}
          to={(location) => {
            if (user.roles.includes("Pilot")) return "/pilot";
            else if (user.roles.includes("Ats")) return "/ats";
            else if (user.roles.includes("Admin")) return "/admin";
          }}
        >
          <img className="header-image" src={dronerequest2} alt="" style={{ height: 60 }} />
        </Link>
        {IS_TEST && (
          <div class="blink_me" style={{ color: "white", fontWeight: "bold", display: "flex", flexGrow: 1 }}>
            TEST
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginRight: 60 }}>
            <IOSSwitch checked={checked} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />{" "}
            <div style={{ color: "#FFFFFF", marginLeft: 10 }}>{t("tower_open")}</div>
          </div> */}
          <IconButton
            sx={{ color: "#FFFFFF", borderColor: "#FFFFFF" }}
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                history.push("/profile");
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              {t("profile")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.clear();
                setUser(null);
                history.push("/");
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              {t("logout")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
