import React from "react";
import tower from "../../assets/dr_logo_white_isolated.png";
import naviation from "../../assets/nav2.webp";
import "./Footer.css";
import { APP_VERSION } from "../../constants/constants";

export const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div>
          <a style={{ display: "flex", justifyContent: "flex-end" }} href="https://www.dronerequest.com">
            <img className="footer-image" src={tower} alt="Dronerequest" />
          </a>
        </div>
        <div>
          <div className="footer-text">
            <p>Dronerequest Tower Module {APP_VERSION}</p>
            <p>Alla tider visas för tidszon {Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
          </div>
        </div>
        <div className="footer-attribution">
          <a id="footer-link" href="https://naviation.se">
            <img className="footer-image" src={naviation} alt="Naviation" />
          </a>
        </div>
      </div>
    </footer>
  );
};
